<template>
  <div v-loading="initalLoading">
    <el-card shadow="hover" class="card mb-3" style="width: 100%">
      <div class="card-header bg-light" v-if="isFromProfile">
        <h3 class="title fw-normal mb-0 p-2">Initial Information</h3>
      </div>
      <div class="signature-preview p-4" v-if="showPreview">
        <img :src="userInitial" height="50px" width="100px" />
        <br />
        <el-button type="danger" class="p-2" plain @click="handleInitialEdit"
          ><i class="el-icon-edit"></i> Edit</el-button
        >
      </div>
      <div class="signature-box" v-loading="verificationLoading" v-else>
        <el-form>
          <el-form-item class="mb-05" label="Select Style">
            <el-select
              v-model="value"
              placeholder="Select"
              @change="onChangeStyle"
            >
              <el-option
                v-for="item in outlet"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option
            ></el-select>
          </el-form-item>
          <el-form-item label="Signature Text">
            <el-input
              type="text"
              maxlength="3"
              v-model="signatureText"
              label="Signature"
              placeholder="Enter signature"
            ></el-input>
          </el-form-item>

          <!-- :style="{ 'font-family': tstyle, color: this.signatureColor, width: '100px', height: '50px'}" -->
          <Div
            class="fontsize"
            :style="{ 'font-family': tstyle, color: this.signatureColor }"
          >
            {{ this.signatureText }}
          </Div>
        </el-form>
        <div class="my-3">
          <button
            type="button"
            class="color-btn red"
            @click="signatureColor = '#FF0000'"
          ></button>
          <button
            type="button"
            class="color-btn black"
            @click="signatureColor = '#000000'"
          ></button>
          <button
            type="button"
            class="color-btn green"
            @click="signatureColor = '#008000'"
          ></button>
        </div>
        <p
          class="error"
          v-if="
            getSignatureUpdateErrors && getSignatureUpdateErrors.critical_error
          "
        >
          {{ getSignatureUpdateErrors.critical_error }}
        </p>
        <div class="mb-0 p-2">
          <el-button @click="closeInitial">Cancel</el-button>
          <el-button
            style="background-color: #409eff; color: #ffffff"
            class="type-2"
            @click="saveCapturedSignature"
            >Save Changes</el-button
          >
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import textToImage from "text-to-image";
export default {
  name: "Initial",
  props: {
    isFromProfile: Boolean,
  },
  data() {
    return {
      outlet: [
        {
          value: "AndyBold",
          label: "AndyBold",
        },
        {
          value: "ArialRoundedMTBold",
          label: "Arial Rounded MT Bold",
        },
        {
          value: "BickleyScript",
          label: "Bickley Script",
        },

        {
          value: "VladimirScript",
          label: "Vladimir Script",
        },

        {
          value: "KunstlerScript",
          label: "Kunstler Script",
        },

        {
          value: "Mistral",
          label: "Mistral",
        },
        {
          value: "Brush Script MT",
          label: "Brush Script MT",
        },
        {
          value:"Shears",
          label:"Shears",
        },
        {
          value:"Royaliya",
          label:"Royaliya",
        },
        {
          value: "Cookie",
          label: "Cookie",
        },
        {
          value:"Playfair Display",
          label:"Playfair Display",
        },
        {
          value:"Kaushan Script",
          label:"Kaushan Script",
        },
        {
          value:"Arizonia",
          label:"Arizonia",
        },
        {
          value:"Tangerine",
          label:"Tangerine",
        },
        {
          value: "Lucida Handwriting",
          label: "Lucida Handwriting",
        },
        {
          value: "Segoe Script",
          label: "Segoe Script",
        },
        // {
        //   value: "BRUSHSCI",
        //   label: "BRUSHSCI",
        // },
        {
          value: "FREESCPT",
          label: "FREESCPT",
        },
        // {
        //   value: "Gistesy",
        //   label: "Gistesy",
        // },
        {
          value: "NanumPenScript-Regular",
          label: "NanumPenScript-Regular",
        },
        {
          value: "NothingYouCouldDo-Regular",
          label: "NothingYouCouldDo-Regular",
        },
        {
          value: "RAGE",
          label: "RAGE",
        },
        // {
        //   value: "RockSalt",
        //   label: "RockSalt",
        // },
        // {
        //   value: "Sacramento-Regular",
        //   label: "Sacramento-Regular",
        // },
        {
          value: "SignPainterHouseScript",
          label: "SignPainterHouseScript",
        },
        {
          value: "KUNSTLER",
          label: "KUNSTLER",
        },
      ],
      value: "Tangerine",
      tstyle: "Tangerine",
      activeName: "drawer",
      signatureText: "",
      signatureColor: "#000",
      penColor: "black",
      unlockSignaturePad: false,
      images: [],
      signatureImage: null,
      signatureLoading: false,
      userSignatureData: {},
      verificationLoading: false,
      showPreview: false,
      signatureType: false,
      userInitial: null,
      initalLoading: false,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getSignatureUpdateStatus",
      "getSignatureUpdateErrors",
      "getInitialUrl",
    ]),
  },
  mounted() {
    // if (this.getAuthenticatedUser && this.getAuthenticatedUser.initial) {
    //   this.showPreview = true;
    // } else {
    //   this.getCurrentUSerInitial();
    // }
    this.getCurrentUSerInitial();
    this.getInitialText();
  },
  methods: {
    handleInitialEdit() {
      //this.signatureText = "";
      this.showPreview = false;
    },
    closeInitial() {
      this.signatureText = "";
      this.showPreview = true;
      this.getInitialText();
    },
    async getCurrentUSerInitial() {
      try {
        this.initalLoading = true;
        await this.$store.dispatch("auth/fetchInitialSignature");
        if (this.getInitialUrl) {
          this.userInitial = this.getInitialUrl;
          this.showPreview = true;
          this.initalLoading = false;
        } else {
          this.showPreview = false;
          this.initalLoading = false;
        }
      } catch (err) {
        this.initalLoading = false;
        console.log(err);
      }
    },
    async onChangeStyle(font) {
      this.tstyle = font;
    },

    async getInitialText() {
      this.signatureText = "";
      this.signatureText += this.getAuthenticatedUser.first_name
        ? this.getAuthenticatedUser.first_name[0].toUpperCase()
        : "";
      this.signatureText += this.getAuthenticatedUser.last_name
        ? this.getAuthenticatedUser.last_name[0].toUpperCase()
        : "";
        // if(!this.getInitialUrl || this.getInitialUrl == null && !this.getSignatureUpdateStatus){
        //   await this.saveCapturedSignature()
        // }
        
    },
    async saveCapturedSignature() {
      try {
        let empty = true;

        if (this.signatureText) {
          await this.generateTextImage();
          empty = false;
        }  
        if (!empty) {
          this.verificationLoading = true;
          await this.$store.dispatch("auth/updateUserInital", {
            initial: this.signatureImage,
          });
          this.userInitial = this.signatureImage;
          this.verificationLoading = false;
        } else {
          this.$notify.warning({
            title: "Warning",
            message: "Please add inital",
          });
          return;
        }

        if (this.getSignatureUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Initial updated successfully",
          });
          this.getCurrentUSerInitial();
          this.showPreview = true;
        } else {
          if (!this.getSignatureUpdateErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error at verifying the Initial",
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    generateTextImage() {
      // console.log(">>> Initial.vue > generateTextImage() called");
      // let w = 50;
      let margin = 3;
      let fontSize = 50;
      let lineHeight = 50;
      if (this.tstyle == "FREESCPT") {
        // w = 30;
      } else if (this.tstyle == "NanumPenScript-Regular") {
        // w = 30;
      } else if (this.tstyle == "NothingYouCouldDo-Regular") {
        // w = 40;
        // lineHeight = 50;
        fontSize = 25;
      } else if (this.tstyle == "RAGE") {
        // w = 35;
        lineHeight = 35;
      } else if (this.tstyle == "KUNSTLER") {
        // w = 25;
      } else if (this.tstyle == "Brush Script MT") {
        // w = 45;
      } else if (this.tstyle == "SignPainterHouseScript") {
        lineHeight = 35;
        // w = 30;
      } else if (this.tstyle == "Segoe Script") {
        // w = 30;
        fontSize = 25;
        margin = 5;
      } else if (this.tstyle == "Lucida Handwriting") {
        // w = 35;
        // lineHeight = 35;
        fontSize = 25;
      } else if (this.tstyle == "BickleyScript") {
        fontSize = 45;
      } else if (this.tstyle == "VladimirScript") {
        fontSize = 25;
      } else if (this.tstyle == "KunstlerScript") {
        fontSize = 25;
      } else if (this.tstyle == "Mistral") {
        fontSize = 25;
      }
      else if (this.tstyle == "Shears") {
       fontSize = 24;
      }
      else if (this.tstyle == "Royaliya") {
       fontSize = 24;
      }
      else if (this.tstyle == "Cookie") {
       fontSize = 36;
      }
      else if (this.tstyle == "Playfair Display") {
       fontSize = 36;
      }
      else if (this.tstyle == "Kaushan Script") {
       fontSize = 36;
      }
      else if (this.tstyle == "Arizonia") {
       fontSize = 36;
      }
        else if (this.tstyle == "Tangerine") {
       fontSize = 48;
      }

      // let imgWidth =
      //   this.signatureText.length * w < 186
      //     ? 186
      //     : this.signatureText.length * w;

      textToImage
        .generate("" + this.signatureText, {
          textColor: this.signatureColor,
          fontFamily: this.tstyle,
          maxWidth: 100,
          textAlign: "center",
          verticalAlign: "center",
          fontSize: fontSize,
          // fontFamily: 'Arial',
          lineHeight: lineHeight,
          margin: margin,
          bgColor: "transparent",
          // textColor: 'red',
        })
        .then((dataUri) => {
          this.signatureImage = dataUri;
        });
    },

    setColor(color) {
      this.penColor = color;
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
  },
};
</script>
<style lang="scss" scoped>
.signature-box {
  display: block;
  max-width: 500px;
  margin: 0 auto 1.5em;
  margin-bottom: 0px;
  .sign-image {
    // border: 1px solid #dddddd;
    border-radius: 4px;
    margin-bottom: 1em;
    background-color: rgba(0, 0, 0, 0.025);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 80px;
      height: 1px;
      background-color: #999999;
      z-index: 0;
    }
  }
  .color-btn {
    height: 18px;
    width: 18px;
    margin-right: 1em;
    border-radius: 10px;
    border: 1px solid #efefef;
    &.red {
      background-color: #f34424;
    }
    &.blue {
      background-color: #333333;
    }
    &.black {
      background-color: black;
    }
    &.green {
      background-color: #3d8116;
    }
  }
}
.signature-preview {
  // background-color: #fdfdfd;
  img {
    margin-bottom: 1em;
    // background-color: #ffffff;
    // border-radius: 5px;
    // box-shadow: 0 0 10px 0 #efefef;
    margin: 0 auto 1.5em;
  }
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform : scale(1.0);
}
</style>